import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { accounts } from 'services';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from 'material-design/Statistic/StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from 'material-design/Statistic/StatisticCategory/utils/checkRangeInOneYear';

//Components
import FiltersDetail from 'material-design/Statistic/Trends/FiltersDetail/FiltersDetail';
import TableElement from '../TableElement/TableElement';
import Skeleton from './Skeleton/Skeleton';
import EmptyData from '../EmptyData/EmptyData';
import { Pagination } from 'material-design/components';

// Styles
import styles from './TopRecordingsDetail.module.css';

const TopRecordingsDetail = ({ accountId }) => {
	let { pathname } = useLocation();
	const { params } = useRouteMatch();
	const { lang } = useContext(LangContext);

	// Date Picker
	const { showModal } = useContext(UIContext);
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);

	const [isFirstRender, setIsFirstRender] = useState(true);

	const [sidebarFiltersData, setSideBarFiltersData] = useState({
		performers: [],
		releases: [],
		recordings: [],
		countries: [],
	});

	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	// Countries
	const [noCountries, setNoCountries] = useState(false);
	const [isInitial, setIsInitial] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [filtersData, setFiltersData] = useState({
		countries: [],
	});
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const [recordings, setRecordings] = useState([]);

	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);

	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
			// setStatisticData({ ...statisticData });
		}
	};

	const handleCloseFilterOutlets = () => {
		setStatisticData({ ...statisticData });
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });
		setSideBarFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	const getRecordingsRequest = async () => {
		setLoading(true);
		setRecordings([]);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getRecordingsInRelease(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				params.id,
				countriesCode?.length ? countriesCode : [],
				page,
				10
			);
			setTotal(response.data.total);
			setCurrentPage(response.data.current_page);
			setRecordings(response.data.global);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		getRecordingsRequest();
	}, [statisticData, sidebarFiltersData, page]);

	return (
		<>
			<FiltersDetail
				accountId={accountId}
				lang={lang}
				handleCheckedOutlets={handleCheckedOutlets}
				changeField={changeField}
				statisticData={datePickerData}
				errors={errors}
				handleCheckedPeriod={handleCheckedPeriod}
				setApplyDate={setApplyDate}
				applyDateFilter={applyDateFilter}
				setIsLastCalendar={setIsLastCalendar}
				handleCheckedCountry={handleCheckedCountry}
				handleCloseFilterOutlets={handleCloseFilterOutlets}
			/>
			<div className={styles.wrapper}>
				<h2 className={styles.title}>
					<FormattedMessage id={'rod.statistic.tracks'} />
				</h2>
				{loading ? (
					<Skeleton />
				) : recordings.length > 0 ? (
					<>
						<div className={styles.table}>
							<div className={styles.tableHeading}>
								<p className={styles.tableHeadingTitle}>
									<FormattedMessage id={'rod.statistic.trends.column.name'} />
								</p>
								<p className={styles.tableHeadingTitle}>
									<FormattedMessage
										id={'rod.statistic.trends.column.performer'}
									/>
								</p>
								<p
									className={`${styles.tableHeadingTitle} ${styles.selectedPeriod}`}
								>
									<FormattedMessage
										id={'rod.statistic.trends.column.listening-selected-period'}
									/>
								</p>
								<p className={styles.tableHeadingTitle}>
									<FormattedMessage
										id={'rod.statistic.trends.countries.growth-week'}
									/>
								</p>
								<p className={styles.tableHeadingTitle}>
									<FormattedMessage
										id={'rod.statistic.trends.countries.growth-month'}
									/>
								</p>
							</div>
							<ul className={styles.tableList}>
								{recordings.map((item, index) => (
									<TableElement
										key={index}
										item={item}
										index={index}
										currentPage={currentPage}
									/>
								))}
							</ul>
						</div>
						{total > 10 && (
							<div className={styles.pagination}>
								<Pagination
									page={page}
									total={total}
									paginate={(pageNumber) => {
										if (pageNumber !== page) {
											setLoading(true);
											setPage(pageNumber);
										}
									}}
									items_on_page={10}
								/>
							</div>
						)}
					</>
				) : (
					<div className={styles.empty}>
						<EmptyData />
					</div>
				)}
			</div>
		</>
	);
};

export default TopRecordingsDetail;
