import React from 'react';

import styles from './ContentPage.module.css';
import Release from './Release/Release';

const ContentPage = ({ accountId, tab }) => {
	const renderScreen = (tab, accountId) => {
		switch (tab) {
			case 'release':
				return <Release accountId={accountId} />;
			case 'recording':
				return <>трек</>;
			case 'audience':
				return <>аудитория</>;
			case 'sources':
				return <>источники</>;
			default:
				return <Release accountId={accountId} />;
		}
	};

	return <div className={styles.wrapper}>{renderScreen(tab, accountId)}</div>;
};

export default ContentPage;
