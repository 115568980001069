import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getReleaseTypeById } from 'utils';

import { accounts } from 'services';
import SkeletonDetailHeading from './SkeletonDetailHeading/SkeletonDetailHeading';
import SkeletonStreams from './SkeletonStreams/SkeletonStreams';
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

import placeholderArtist from 'images/trends/topArtists/placeholderArtist.svg';
import styles from './MetadataDetail.module.css';

const MetadataDetail = ({ accountId, params, typePage }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [dataStreams, setDataStreams] = useState({});

	const [release, setRelease] = useState([]);
	const [isLoadingTopReleases, setIsLoadingTopReleases] = useState(false);
	const [metadata, setMetadata] = useState({});
	const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
	const [isImageBroken, setIsImageBroken] = useState(false);

	const [coverRelease, setCoverRelease] = useState('');

	const getStreams = () => {
		setIsLoading(true);
		accounts
			.getAccountStatisticTrendsArtistStreams(
				false,
				accountId,
				typePage === 'artist' ? params.id : [],
				typePage === 'recording' ? params.id : [],
				typePage === 'release' ? params.id : []
			)
			.then((res) => {
				setDataStreams(res.data.data);
				setIsLoading(false);
			});
	};

	// Get Metadata
	const getReleaseMetadataInfo = () => {
		setIsLoadingMetadata(true);
		// 2986965
		accounts.getReleaseInfoForBrowsing(accountId, params.id).then((res) => {
			setMetadata(res.data.data);
			setIsLoadingMetadata(false);
		});
	};

	const getRecordingMetadataInfo = () => {
		setIsLoadingMetadata(true);
		// 2828468
		accounts.getRecordingMetadata(accountId, params.id).then((res) => {
			setMetadata(res.data.data);
			setIsLoadingMetadata(false);
		});
	};

	const getTopReleases = async () => {
		setIsLoadingTopReleases(true);
		setRelease([]);
		try {
			const topReleasesRes = await accounts.getAccountStatisticTrendsTopReleases(
				false,
				accountId,
				[],
				'',
				'',
				[],
				params.id,
				[],
				[],
				1
			);

			const topReleases = topReleasesRes.data.data.global;

			if (topReleasesRes.data.data.total) {
				const releasesIds = topReleases.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setRelease(topReleasesInfo);
				} else {
					setRelease(topReleases);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoadingTopReleases(false);
		}
	};

	const getCoverRelease = () => {
		accounts.getReleaseCoverForBrowsing(accountId, params.id).then((res) => {
			setCoverRelease(res.data.path_xm);
		});
	};

	const renderPercentChange = (percentChange) => {
		return (
			<p
				className={`${styles.progress} ${
					percentChange.includes('+') && styles.upStreams
				} ${percentChange.includes('-') && styles.downStreams}`}
			>
				<span className={styles.progressValue}>
					<svg
						width="11"
						height="12"
						viewBox="0 0 11 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.16645 3.21875V11.3334H4.83312V3.21875L1.25712 6.79475L0.314453 5.85208L5.49979 0.666748L10.6851 5.85208L9.74245 6.79475L6.16645 3.21875Z"
							fill="currentColor"
						/>
					</svg>
					{percentChange}{' '}
				</span>
				<FormattedMessage id={'rod.statistic.trends.artist.changes-week'} />
			</p>
		);
	};

	const numericalRank = () => {
		if (dataStreams.allStreams?.length >= 7) {
			return 'млн';
		} else if (
			dataStreams.allStreams?.length <= 6 &&
			dataStreams.allStreams?.length >= 4
		) {
			return 'тыс';
		} else {
			return 'раз';
		}
	};

	const checkImage = async () => {
		if (release.cover) {
			try {
				const response = await fetch(release.cover, { method: 'HEAD' });
				if (!response.ok) {
					setIsImageBroken(true);
				}
			} catch {
				setIsImageBroken(true);
			}
		} else {
			setIsImageBroken(true);
		}
	};

	useEffect(() => {
		getStreams();
		if (typePage === 'release') {
			getReleaseMetadataInfo();
		}
		if (typePage === 'recording') {
			getRecordingMetadataInfo();
		}
		if (typePage === 'release' || typePage === 'recording') {
			getTopReleases();
		}

		if (typePage === 'release') {
			getCoverRelease();
		}
	}, []);

	useEffect(() => {
		checkImage();
	}, [release.cover]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.artistInfo}>
				{typePage !== 'artist' && isLoadingMetadata ? (
					<SkeletonDetailHeading typePage={typePage} />
				) : (
					<>
						<div
							className={`${styles.photo} ${
								typePage === 'artist' ? styles.artistPhotoMain : {}
							}`}
						>
							{typePage === 'recording' && (
								<img
									src={
										!release.cover?.length || isImageBroken
											? emptyCoverAlbum
											: release.cover
									}
									alt={metadata?.title}
								/>
							)}
							{typePage === 'release' && (
								<img src={coverRelease} alt={metadata?.title} />
							)}
							{typePage === 'artist' && (
								<img
									src={placeholderArtist}
									width={64}
									height={64}
									alt={params.id}
								/>
							)}
						</div>
						<div>
							<div className={styles.wrapperName}>
								<h2 className={styles.name}>
									{typePage === 'artist' ? params.id : metadata?.title}
								</h2>
								{typePage === 'release' && (
									<span className={styles.releaseType}>
										{getReleaseTypeById(metadata?.release_type_id)}
									</span>
								)}
							</div>
							{(typePage === 'release' || typePage === 'recording') && (
								<>
									<div className={styles.wrapperIsrc}>
										<span className={styles.isrcLabel}>
											{typePage === 'release' && <>upc</>}
											{typePage === 'recording' && <>isrc</>}
										</span>
										<span className={styles.isrcValue}>
											{typePage === 'release' && metadata?.ean}
											{typePage === 'recording' && metadata?.isrc}
										</span>
									</div>
									<ul className={styles.listArtists}>
										{metadata?.performers.length > 0 &&
											metadata?.performers?.map((item, index) => (
												<li key={index} className={styles.artist}>
													<div className={styles.artistPhoto}>
														<img
															src={placeholderArtist}
															alt={item}
															width={16}
															height={16}
														/>
													</div>
													<Link
														to={`/accounts/${accountId}/statistic/trends/artist/${item}`}
													>
														<p className={styles.artistName}>{item.title}</p>
													</Link>
												</li>
											))}
									</ul>
								</>
							)}
						</div>
					</>
				)}
			</div>
			<div className={styles.streamsWrapper}>
				{isLoading ? (
					<SkeletonStreams />
				) : (
					<>
						<p className={styles.streamsTitle}>
							<FormattedMessage
								id={'rod.statistic.trends.artist.streams-all-time'}
							/>
						</p>
						<p className={styles.streamsValue}>
							{Number(dataStreams.allStreams).toLocaleString('ru-RU')}{' '}
							{numericalRank()}
						</p>
						{renderPercentChange(dataStreams.percentChange)}
					</>
				)}
			</div>
		</div>
	);
};

export default MetadataDetail;
