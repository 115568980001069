import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { getCountryCode } from 'material-design/Statistic/StatisticCategory/StatisticByTerritoriesSection/StatisticByTerritoriesChart/utils/getCountryCode';
import { getCountryName } from 'material-design/Statistic/StatisticCategory/StatisticByTerritoriesSection/StatisticByTerritoriesChart/utils/getCountryName';
// import { getTenTopCountries } from 'material-design/Statistic/StatisticCategory/StatisticByTerritoriesSection/StatisticByTerritoriesChart/utils/getTenTopCountries';
import lscache from 'lscache';

import TopCountriesSkeleton from './Skeleton/TopCountriesSkeleton';
import EmptyData from '../EmptyData/EmptyData';
import styles from './TopCountries.module.css';

const TopCountries = ({ accountMap, geo, isLoadingTopCountries }) => {
	const lang = localStorage.getItem('lang');
	const countriesCache = lscache.get('countriesCashe');
	const [countries, setCountries] = useState([]);
	const [tenTopCountries, setTenTopCountries] = useState([]);

	const sumByCountry = (countryProps) => {
		countryProps.code = getCountryCode(countryProps.name);

		const dataBySelectedCountry = accountMap.filter(
			(element) => element.territory_code === countryProps.code
		);

		let auditions = 0;
		dataBySelectedCountry.forEach(
			(element) => (auditions = auditions + parseInt(element.stat_count))
		);

		return auditions;
	};

	const getCountriesList = () => {
		if (geo) {
			const result = geo.map((item) => ({
				code: item.id,
				name: item.properties.name,
			}));

			setCountries(result);
		}
	};

	useEffect(() => {
		if (geo.length) {
			getCountriesList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geo]);

	useEffect(() => {
		const getAuditionsByCountries = countries.map((item) => ({
			...item,
			auditions: sumByCountry(item),
		}));
		const filterNonEmptyCountries = getAuditionsByCountries.filter(
			(item) => item.auditions
		);
		const sortedCountriesByAuditions = filterNonEmptyCountries.sort(
			(a, b) => b.auditions - a.auditions
		);

		const getTenTopCountries = (data, countriesCache) => {
			let result = [];
			result = data.slice(0, 10)?.filter((item) => item.auditions);
			result.map((item) => {
				if (countriesCache) {
					const country = countriesCache?.filter((el) => el.code === item.code);
					if (country.length > 0) {
						item.title_ru = country[0].title_ru;
						item.title_en = country[0].title_en;
					}
					return item;
				}
				return item;
			});

			return result;
		};

		setTenTopCountries(
			getTenTopCountries(sortedCountriesByAuditions, countriesCache)
		);

		// if (sortedCountriesByAuditions[0]) {
		// 	setMaxAuditions(sortedCountriesByAuditions[0].auditions);
		// }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries, accountMap, geo]);

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.top-countries.title'} />
			</h2>
			{isLoadingTopCountries ? (
				<TopCountriesSkeleton />
			) : accountMap.length ? (
				<div className={styles.table}>
					<div className={styles.tableHeading}>
						<p className={styles.tableHeadingTitle}>
							<FormattedMessage
								id={'rod.statistic.trends.countries.title-country'}
							/>
						</p>
						<p className={styles.tableHeadingTitle}>
							<FormattedMessage id={'rod.statistic.trends.top.streams'} />
						</p>
					</div>

					<ul className={styles.topTenData}>
						{tenTopCountries.map((item, index) => (
							<li
								className={styles.countryElement}
								key={getCountryName(item, lang)}
							>
								<span className={styles.countryPlace}>{index + 1}</span>
								<span className={styles.countryName}>
									{getCountryName(item, lang)}
								</span>
								<span className={styles.countryValue}>
									{item.auditions.toLocaleString('ru-RU')}
								</span>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div className={styles.empty}>
					<EmptyData />
				</div>
			)}
		</div>
	);
};

export default TopCountries;
