import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getReleaseTypeById } from 'utils';

import { accounts } from 'services';
import SkeletonDetailHeading from './SkeletonDetailHeading/SkeletonDetailHeading';
import SkeletonStreams from './SkeletonStreams/SkeletonStreams';
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

import placeholderArtist from 'images/trends/topArtists/placeholderArtist.svg';
import styles from './MetadataDetail.module.css';

const MetadataDetail = ({ accountId, params, typePage }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [dataStreams, setDataStreams] = useState({});

	const [release, setRelease] = useState([]);
	const [isLoadingTopReleases, setIsLoadingTopReleases] = useState(false);
	const [metadata, setMetadata] = useState({});
	const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
	const [isImageBroken, setIsImageBroken] = useState(false);

	const [coverRelease, setCoverRelease] = useState('');
	const [isCopied, setIsCopied] = useState(false);

	const getStreams = () => {
		setIsLoading(true);
		accounts
			.getAccountStatisticTrendsArtistStreams(
				false,
				accountId,
				typePage === 'artist' ? params.id : [],
				typePage === 'recording' ? params.id : [],
				typePage === 'release' ? params.id : []
			)
			.then((res) => {
				setDataStreams(res.data.data);
				setIsLoading(false);
			});
	};

	// Get Metadata
	const getReleaseMetadataInfo = () => {
		setIsLoadingMetadata(true);
		// 2986965
		accounts.getReleaseInfoForBrowsing(accountId, params.id).then((res) => {
			setMetadata(res.data.data);
			setIsLoadingMetadata(false);
		});
	};

	const getRecordingMetadataInfo = () => {
		setIsLoadingMetadata(true);
		// 2828468
		// 2828498
		// params.id
		accounts.getRecordingMetadata(accountId, params.id).then((res) => {
			setMetadata(res.data.data);
			setIsLoadingMetadata(false);
		});
	};

	const getTopReleases = async () => {
		setIsLoadingTopReleases(true);
		setRelease([]);
		try {
			const topReleasesRes = await accounts.getAccountStatisticTrendsTopReleases(
				false,
				accountId,
				[],
				'',
				'',
				[],
				params.id,
				[],
				[],
				1
			);

			const topReleases = topReleasesRes.data.data.global;

			if (topReleasesRes.data.data.total) {
				const releasesIds = topReleases.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setRelease(topReleasesInfo);
				} else {
					setRelease(topReleases);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoadingTopReleases(false);
		}
	};

	const getCoverRelease = () => {
		accounts.getReleaseCoverForBrowsing(accountId, params.id).then((res) => {
			setCoverRelease(res.data.path_xm);
		});
	};

	const renderPercentChange = (percentChange) => {
		return (
			<p
				className={`${styles.progress} ${
					percentChange.includes('+') && styles.upStreams
				} ${percentChange.includes('-') && styles.downStreams}`}
			>
				<span className={styles.progressValue}>
					<svg
						width="11"
						height="12"
						viewBox="0 0 11 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.16645 3.21875V11.3334H4.83312V3.21875L1.25712 6.79475L0.314453 5.85208L5.49979 0.666748L10.6851 5.85208L9.74245 6.79475L6.16645 3.21875Z"
							fill="currentColor"
						/>
					</svg>
					{percentChange}{' '}
				</span>
				<FormattedMessage id={'rod.statistic.trends.artist.changes-week'} />
			</p>
		);
	};

	const numericalRank = () => {
		if (dataStreams.allStreams?.length >= 7) {
			return <FormattedMessage id={'rod.statistic.trends.millions'} />;
		} else if (
			dataStreams.allStreams?.length <= 6 &&
			dataStreams.allStreams?.length >= 4
		) {
			return <FormattedMessage id={'rod.statistic.trends.thousand'} />;
		} else {
			return '';
		}
	};

	const handleClipboard = (typePage) => {
		switch (typePage) {
			case 'artist':
				navigator.clipboard.writeText(params.id);
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
				break;
			case 'release':
				navigator.clipboard.writeText(metadata?.ean ?? '');
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
				break;
			case 'recording':
				navigator.clipboard.writeText(metadata?.isrc ?? '');
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
				break;
			default:
				return '';
		}
	};

	const checkImage = async () => {
		if (release.cover) {
			try {
				const response = await fetch(release.cover, { method: 'HEAD' });
				if (!response.ok) {
					setIsImageBroken(true);
				}
			} catch {
				setIsImageBroken(true);
			}
		} else {
			setIsImageBroken(true);
		}
	};

	useEffect(() => {
		getStreams();
		if (typePage === 'release') {
			getReleaseMetadataInfo();
		}
		if (typePage === 'recording') {
			getRecordingMetadataInfo();
		}
		if (typePage === 'release' || typePage === 'recording') {
			getTopReleases();
		}

		if (typePage === 'release') {
			getCoverRelease();
		}
	}, []);

	useEffect(() => {
		checkImage();
	}, [release.cover]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.artistInfo}>
				{typePage !== 'artist' && isLoadingMetadata ? (
					<SkeletonDetailHeading typePage={typePage} />
				) : (
					<>
						<div
							className={`${styles.photo} ${
								typePage === 'artist' ? styles.artistPhotoMain : {}
							}`}
						>
							{typePage === 'recording' && (
								<img
									src={
										!release.cover?.length || isImageBroken
											? emptyCoverAlbum
											: release.cover
									}
									alt={metadata?.title}
								/>
							)}
							{typePage === 'release' && (
								<img
									src={
										!release.cover?.length || isImageBroken
											? emptyCoverAlbum
											: coverRelease
									}
									alt={metadata?.title}
								/>
							)}
							{typePage === 'artist' && (
								<img
									src={placeholderArtist}
									width={64}
									height={64}
									alt={params.id}
								/>
							)}
						</div>
						<div>
							<div className={styles.wrapperName}>
								<h2 className={styles.name}>
									{typePage === 'artist' ? params.id : metadata?.title}
								</h2>
								{typePage === 'release' && (
									<span className={styles.releaseType}>
										{getReleaseTypeById(metadata?.release_type_id)}
									</span>
								)}
							</div>
							<div className={styles.wrapperIsrc}>
								<span className={styles.isrcLabel}>
									{typePage === 'release' && <>upc</>}
									{typePage === 'recording' && <>isrc</>}
									{typePage === 'artist' && <>id</>}
								</span>
								<span className={styles.isrcValue}>
									{typePage === 'release' && metadata?.ean}
									{typePage === 'recording' && metadata?.isrc}
									{typePage === 'artist' && params.id}
									<button
										className={styles.copyButton}
										onClick={() => handleClipboard(typePage)}
									>
										<svg
											width="16"
											height="18"
											viewBox="0 0 16 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.46322 4.00008V1.50008C3.46322 1.27907 3.55101 1.06711 3.70729 0.910826C3.86357 0.754545 4.07554 0.666748 4.29655 0.666748H14.2965C14.5176 0.666748 14.7295 0.754545 14.8858 0.910826C15.0421 1.06711 15.1299 1.27907 15.1299 1.50008V13.1667C15.1299 13.3878 15.0421 13.5997 14.8858 13.756C14.7295 13.9123 14.5176 14.0001 14.2965 14.0001H11.7965V16.5001C11.7965 16.9601 11.4215 17.3334 10.9574 17.3334H0.969049C0.859155 17.3341 0.750214 17.313 0.648485 17.2714C0.546756 17.2299 0.454244 17.1686 0.376265 17.0912C0.298286 17.0137 0.236377 16.9216 0.194095 16.8202C0.151812 16.7188 0.129991 16.61 0.129883 16.5001L0.132383 4.83341C0.132383 4.37341 0.507383 4.00008 0.970716 4.00008H3.46322ZM1.79822 5.66675L1.79655 15.6667H10.1299V5.66675H1.79822ZM5.12988 4.00008H11.7965V12.3334H13.4632V2.33341H5.12988V4.00008Z"
												fill="#888888"
											/>
										</svg>
										{isCopied && (
											<span className={styles.copiedTooltip}>
												<FormattedMessage id={'rod.statistic.trends.copied'} />
											</span>
										)}
									</button>
								</span>
							</div>
							{(typePage === 'release' || typePage === 'recording') && (
								<ul className={styles.listArtists}>
									{metadata?.artists?.length > 0 &&
										metadata?.artists?.map((item, index) => (
											<li key={item.id} className={styles.artist}>
												<div className={styles.artistPhoto}>
													<img
														src={placeholderArtist}
														alt={item}
														width={16}
														height={16}
													/>
												</div>
												<Link
													to={`/accounts/${accountId}/statistic/trends/artist/${item.id}`}
												>
													<p className={styles.artistName}>{item.title}</p>
												</Link>
											</li>
										))}
								</ul>
							)}
						</div>
					</>
				)}
			</div>
			<div className={styles.streamsWrapper}>
				{isLoading ? (
					<SkeletonStreams />
				) : (
					<>
						<p className={styles.streamsTitle}>
							<FormattedMessage
								id={'rod.statistic.trends.artist.streams-all-time'}
							/>
						</p>
						<p className={styles.streamsValue}>
							{Number(dataStreams.allStreams).toLocaleString('ru-RU')}{' '}
							{numericalRank()}
						</p>
						{renderPercentChange(dataStreams.percentChange)}
					</>
				)}
			</div>
		</div>
	);
};

export default MetadataDetail;
