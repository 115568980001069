import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

import Tabs from './Tabs/Tabs';
import ContentPage from './ContentPage/ContentPage';
import MetadataDetail from '../MetadataDetail/MetadataDetail';

import styles from './RecordingPage.module.css';

const RecordingPage = () => {
	const { accountId } = useContext(AuthContext);
	const { params } = useRouteMatch();
	const { showBackNavi, hideBackNavi } = useContext(UIContext);
	const [tab, setTab] = useState('recording');

	const handleClickTab = (tab) => {
		switch (tab) {
			case 'recording':
				setTab('recording');
				break;
			case 'audience':
				setTab('audience');
				break;
			case 'sources':
				setTab('sources');
				break;
			default:
				setTab('recording');
		}
	};

	useEffect(() => {
		showBackNavi(`/accounts/${accountId}/statistic/trends`);
		return () => {
			hideBackNavi();
		};
	}, []);

	return (
		<div>
			<Tabs handleClickTab={handleClickTab} tab={tab} />
			<div className={styles.wrapperMetadata}>
				<MetadataDetail
					accountId={accountId}
					params={params}
					typePage="recording"
				/>
			</div>
			<ContentPage accountId={accountId} tab={tab} />
		</div>
	);
};

export default RecordingPage;
