// Core
import React, { useContext, useEffect, useState } from 'react';
import { withUI, withAuth, withRoot } from 'hocs';
import { useLocation } from 'react-router-dom';
import { accounts } from 'services';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from '../StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from '../StatisticCategory/utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../StatisticCategory/utils/getStartEndDatesForOneYear';
import countriesFiles from 'countries.js';
import outletsColors from './utils/outletsColors';

// Components
import StatisticFilterModalTrend from './StatisticFilterModalTrend/StatisticFilterModalTrend';
import FilterOutlets from './TrendsFilters/FilterOutlets/FilterOutlets';
import DatesBlockNew from '../StatisticCategory/StatisticByDatesSection/DatesBlockNew/DatesBlockNew';
import ProductTypesGraph from './ProductTypesGraph/ProductTypesGraph';
import TopOutletsGraphVis from './TopOutletsGraphVis/TopOutletsGraphVis';
import TopArtists from './TopArtists/TopArtists';
import TopReleasesAndRecordings from './TopReleasesAndRecordings/TopReleasesAndRecordings';

// Styles
import styles from './Trends.module.css';
import '../StatisticCategory/StatisticByDatesSection/DatesBlockNew/DateBlockNewForDate.css';

import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';
import TopCountries from './TopCountries/TopCountries';

const Trends = (props) => {
	const {
		authContext: { accountId },
	} = props;

	let { pathname } = useLocation();
	const { lang } = useContext(LangContext);

	// Date Picker
	const { showModal } = useContext(UIContext);
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);

	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	//Filters Data
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);
	const [sidebarFiltersData, setSideBarFiltersData] = useState({
		performers: [],
		releases: [],
		recordings: [],
		countries: [],
	});

	//ProductsType
	const [isLoadingProductsType, setIsLoadingProductsType] = useState(true);
	const [typeProducts, setTypeProducts] = useState([]);
	// TopOutlets
	const [isLoadingTopOutlets, setIsLoadingTopOutlets] = useState(true);
	const [topOutlets, setTopOutlets] = useState([]);
	//TopArtists
	const [artists, setArtists] = useState([]);
	const [isLoadingTopArtists, setIsLoadingTopArtists] = useState(true);
	//TopReleases and TopRecordings
	const [topReleases, setTopReleases] = useState([]);
	const [isLoadingTopReleases, setIsLoadingTopReleases] = useState(true);
	const [topRecordings, setTopRecordings] = useState([]);
	const [isLoadingTopRecordings, setIsLoadingTopRecordings] = useState(true);
	const limit = 5;
	//TopCountries
	const [isLoadingTopCountries, setIsLoadingTopCountries] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [geo, setGeo] = useState([]);
	const [topCountries, setTopCountries] = useState([]);

	// Other
	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
			// setStatisticData({ ...statisticData });
		}
	};

	const handleCloseFilterOutlets = () => {
		setStatisticData({ ...statisticData });
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const checkingActiveFilters = () => {
		return Object.values(sidebarFiltersData).some((item) => item.length > 0);
	};

	const startEndDates = getStartEndDatesForOneYear(statisticData, false);

	// Requests
	const getTypeProducts = async () => {
		setIsLoadingProductsType(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});

		try {
			const response = await accounts.getAccountStatisticTypeProducts(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.releases?.length ? sidebarFiltersData.releases : [],
				sidebarFiltersData.recordings?.length
					? sidebarFiltersData.recordings
					: [],
				sidebarFiltersData.performers?.length
					? sidebarFiltersData.performers
					: [],
				countriesCode?.length ? countriesCode : []
			);
			setTypeProducts(response.data.data.data);
			setIsLoadingProductsType(false);
		} catch (error) {
			console.error(error);
			setIsLoadingProductsType(false);
		}
	};

	const getTopOutlets = async () => {
		setIsLoadingTopOutlets(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});

		try {
			const response = await accounts.getAccountStatisticTopOutlets(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.releases?.length ? sidebarFiltersData.releases : [],
				sidebarFiltersData.recordings?.length
					? sidebarFiltersData.recordings
					: [],
				[],
				countriesCode?.length ? countriesCode : []
			);
			let data;
			if (response.data.data.data.length > 6) {
				data = response.data.data.data.slice(0, 5).map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));
				const othersOutlets = response.data.data.data.slice(5).map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));

				const sumOtherOutlets = othersOutlets.reduce(
					(acc, cur) => acc + cur.percentage,
					0
				);

				data[5] = {
					outlet: 'others',
					angle: Number(sumOtherOutlets.toFixed(1)),
					color: '#D4D4D4',
					percentage: sumOtherOutlets.toFixed(1),
					othersOutlets,
				};
			} else {
				data = response.data.data.data.map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));
			}
			setTopOutlets(data);
			setIsLoadingTopOutlets(false);
		} catch (error) {
			console.error(error);
			setIsLoadingTopOutlets(false);
		}
	};

	const getTopArtists = async () => {
		setIsLoadingTopArtists(true);
		try {
			const response = await accounts.getAccountStatisticTrendsTopArtists(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.performers,
				sidebarFiltersData.releases,
				sidebarFiltersData.recordings,
				sidebarFiltersData.countries,
				5
			);
			const data = response.data.data.slice(0, 5);
			setArtists(data);
			setIsLoadingTopArtists(false);
		} catch (error) {
			console.error(error);
			setIsLoadingTopArtists(false);
		}
	};

	const getTopRecordings = async () => {
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		setIsLoadingTopRecordings(true);
		setTopRecordings([]);
		try {
			const topReleasesRes = await accounts.getAccountStatisticTopTracks(
				false,
				accountId,
				1,
				statisticData.start_date,
				statisticData.end_date,
				outletCodes,
				limit,
				sidebarFiltersData.recordings,
				sidebarFiltersData.releases,
				countriesCode,
				sidebarFiltersData.performers
			);

			const topReleases = topReleasesRes.data.data;

			if (topReleases.total) {
				const releasesIds = topReleases.recordings.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.recordings.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setTopRecordings(topReleasesInfo);
				} else {
					setTopRecordings(topReleases.recordings);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoadingTopRecordings(false);
		}
	};

	const getTopReleases = async () => {
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		setIsLoadingTopReleases(true);
		setTopReleases([]);
		try {
			const topReleasesRes = await accounts.getAccountStatisticTrendsTopReleases(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.performers,
				sidebarFiltersData.releases,
				sidebarFiltersData.recordings,
				countriesCode,
				limit
			);

			const topReleases = topReleasesRes.data.data.global;

			if (topReleasesRes.data.data.total) {
				const releasesIds = topReleases.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setTopReleases(topReleasesInfo);
				} else {
					setTopReleases(topReleases);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoadingTopReleases(false);
		}
	};

	const getTopCountries = async () => {
		setIsLoadingTopCountries(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});

		try {
			const response = await accounts.getTrendTopCountries(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.releases?.length ? sidebarFiltersData.releases : [],
				sidebarFiltersData.recordings?.length
					? sidebarFiltersData.recordings
					: [],
				sidebarFiltersData.performers?.length
					? sidebarFiltersData.performers
					: [],
				countriesCode?.length ? countriesCode : []
			);
			setTopCountries(response.data.slice(0, 5));
			setGeo(countriesFiles[0].objects.world.geometries);
			setIsLoadingTopCountries(false);
		} catch (error) {
			console.error(error);
			setIsLoadingTopCountries(false);
		}
	};

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	useEffect(() => {
		getTypeProducts();
		getTopOutlets();
		getTopArtists();
		getTopRecordings();
		getTopReleases();
		getTopCountries();
	}, [statisticData, sidebarFiltersData]);

	return (
		<div className={styles.container}>
			<div className={styles.wrapperFilters}>
				<div className={styles.inputOutlets}>
					<FilterOutlets
						handleCheckedOutlets={handleCheckedOutlets}
						lang={lang}
						className={styles.tracksCheckmarks}
						handleCloseFilterOutlets={handleCloseFilterOutlets}
					/>
				</div>
				<div className={styles.datePicker}>
					<DatesBlockNew
						accountId={accountId}
						changeField={changeField}
						statisticData={datePickerData}
						errors={errors}
						handleCheckedPeriod={handleCheckedPeriod}
						setApplyDate={setApplyDate}
						applyDateFilter={applyDateFilter}
						setIsLastCalendar={setIsLastCalendar}
					/>
				</div>
				<div
					onClick={() => setIsFilterModalActive(true)}
					className={styles.filtersButton}
				>
					<img
						src={!checkingActiveFilters() ? filter_svg : filter_dot_svg}
						alt=""
					/>
					<span>
						<FormattedMessage id={'rod.admin.reports.filters'} />
					</span>
				</div>
			</div>
			<div>
				<div className={styles.graphsWrapper}>
					<ProductTypesGraph
						typeProducts={typeProducts}
						isLoadingProductsType={isLoadingProductsType}
					/>
					<TopOutletsGraphVis
						topOutlets={topOutlets}
						isLoadingTopOutlets={isLoadingTopOutlets}
					/>
				</div>
				<TopArtists
					accountId={accountId}
					artists={artists}
					isLoadingTopArtists={isLoadingTopArtists}
				/>
				<TopReleasesAndRecordings
					accountId={accountId}
					topReleases={topReleases}
					topRecordings={topRecordings}
					isLoadingTopReleases={isLoadingTopReleases}
					isLoadingTopRecordings={isLoadingTopRecordings}
					limit={5}
				/>
				<TopCountries
					geo={geo}
					isLoadingTopCountries={isLoadingTopCountries}
					topCountries={topCountries}
					isDetailPage={false}
				/>
			</div>
			<StatisticFilterModalTrend
				// isAdmin={isAdmin}
				accountId={accountId}
				statisticData={statisticData}
				errors={errors}
				startEndDates={startEndDates}
				active={isFilterModalActive}
				setActive={() => setIsFilterModalActive(false)}
				lang={lang}
				setSideBarFiltersData={setSideBarFiltersData}
				handleSetFilters={(filtersData, isAllChecked, searchValue) => {
					Object.keys(filtersData).forEach((filterKey) => {
						if (filtersData[filterKey]) {
							statisticData[filterKey] = filtersData[filterKey];
						}
					});
					if (isAllChecked) {
						statisticData.tracksIds = [];
					}
					if (searchValue) {
						statisticData.trackSearchValue = searchValue;
					}

					statisticData.checkedAllTracks = isAllChecked;
					statisticData.page = 1;
					setStatisticData({ ...statisticData });
				}}
				checkingActiveFilters={checkingActiveFilters}
			/>
		</div>
	);
};

export default compose(withUI, withAuth, withRoot)(Trends);
