import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Artist from './Artist/Artist';
import TopArtistsEmpty from './TopArtistsEmpty/TopArtistsEmpty';

// Styles
import styles from './TopArtists.module.css';
import TopArtistsSkeleton from './Skeleton/TopArtistsSkeleton';

const TopArtists = ({ accountId, artists, isLoadingTopArtists }) => {
	return (
		<div className={styles.wrapperArtists}>
			<div className={styles.heading}>
				<h2 className={styles.title}>
					<FormattedMessage id={'rod.statistic.trends.top-artists.title'} />
				</h2>
			</div>
			{isLoadingTopArtists ? (
				<TopArtistsSkeleton />
			) : (
				<>
					{artists.length ? (
						<ul className={styles.listArtists}>
							{artists.map((item) => (
								<Artist
									key={item.performer}
									artist={item}
									accountId={accountId}
								/>
							))}
						</ul>
					) : (
						<TopArtistsEmpty />
					)}
				</>
			)}
		</div>
	);
};

export default TopArtists;
