import React from 'react';

import styles from './ContentPage.module.css';
import Artist from './Artist/Artist';

const ContentPage = ({ accountId, tab }) => {
	const renderScreen = (tab, accountId) => {
		switch (tab) {
			case 'artist':
				return <Artist accountId={accountId} />;
			case 'recordings':
				return <>треки</>;
			case 'releases':
				return <>релизы</>;
			case 'audience':
				return <>аудитория</>;
			case 'sources':
				return <>источники</>;
			default:
				return <Artist accountId={accountId} />;
		}
	};

	return <div className={styles.wrapper}>{renderScreen(tab, accountId)}</div>;
};

export default ContentPage;
