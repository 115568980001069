// Core
import React, { useState } from 'react';
import { RadialChart, Hint } from 'react-vis';
import { FormattedMessage } from 'react-intl';

import EmptyData from '../EmptyData/EmptyData';

import codeFormattedOutlets from '../utils/codeFormattedOutlets';

// Styles
import styles from './TopOutletsGraph.module.css';
import ProductTypesGraphSkeleton from '../ProductTypesGraph/Skeleton/ProductTypesGraphSkeleton';

const ProductTypesGraph = ({ topOutlets, isLoadingTopOutlets }) => {
	const [dataDonutSection, setDataDonutSection] = useState({});
	const [isShowHint, setIsShowHint] = useState(false);

	const handleMouseOverGraphic = (datapoint) => {
		const { outlet, percentage, x, y, othersOutlets } = datapoint;
		setDataDonutSection({
			outlet: codeFormattedOutlets[outlet],
			percentage,
			x,
			y,
			othersOutlets,
		});
		setIsShowHint(true);
	};

	const handleMouseOutGraphic = () => {
		setDataDonutSection({});
		setIsShowHint(false);
	};

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.donut-chart.title'} />
			</h2>
			{isLoadingTopOutlets ? (
				<ProductTypesGraphSkeleton />
			) : (
				<div className={styles.graph}>
					{topOutlets.length ? (
						<>
							<RadialChart
								data={topOutlets}
								colorType="literal"
								width={227}
								height={227}
								innerRadius={55}
								radius={100}
								showLabels={false}
								onValueMouseOver={(datapoint) =>
									handleMouseOverGraphic(datapoint)
								}
								onValueMouseOut={() => handleMouseOutGraphic()}
							>
								{isShowHint && (
									<Hint
										value={dataDonutSection}
										style={{
											text: {
												display: 'none',
											},
										}}
									>
										{dataDonutSection.outlet !== 'Others' ? (
											<div className={styles.tooltipGraphicWrapper}>
												<div className={styles.tooltipGraphic}>
													<p className={styles.tooltipGraphicOutlet}>
														{dataDonutSection.outlet}
													</p>
													<p className={styles.tooltipGraphicPercentage}>
														{dataDonutSection.percentage}%
													</p>
												</div>
											</div>
										) : (
											<div className={styles.tooltipGraphicWrapper}>
												<div
													className={`${styles.tooltipGraphic} ${styles.tooltipGraphicOthers}`}
												>
													<div className={styles.tooltipGraphicHeading}>
														<p
															className={`${styles.tooltipGraphicOutlet} ${styles.tooltipGraphicOutletOthers}`}
														>
															<FormattedMessage
																id={
																	'rod.statistic.trends.donut-chart.top-outlets.others'
																}
															/>
														</p>
														<p className={styles.tooltipGraphicPercentage}>
															{dataDonutSection.percentage}%
														</p>
													</div>
													<ul className={styles.tooltipListOutletOthers}>
														{dataDonutSection.othersOutlets.map((item) => (
															<li
																className={styles.tooltipElementOutletOthers}
																key={item.outlet}
															>
																<span className={styles.tooltipGraphicOutlet}>
																	{codeFormattedOutlets[item.outlet]}
																</span>
																<span
																	className={styles.tooltipGraphicPercentage}
																>
																	{item.percentage}%
																</span>
															</li>
														))}
													</ul>
												</div>
											</div>
										)}
									</Hint>
								)}
							</RadialChart>

							<div className={styles.labels}>
								{topOutlets.map((item) => (
									<div className={styles.label} key={item.outlet}>
										<div className={styles.leftSideLabel}>
											<span
												className={styles.labelColorLine}
												style={{ backgroundColor: item.color }}
											></span>
											<p className={styles.labelPercent}>{item.percentage}%</p>
										</div>

										<span className={styles.labelText}>
											{codeFormattedOutlets[item.outlet] !== 'Others' ? (
												codeFormattedOutlets[item.outlet]
											) : (
												<FormattedMessage
													id={
														'rod.statistic.trends.donut-chart.top-outlets.others'
													}
												/>
											)}
										</span>
									</div>
								))}
							</div>
						</>
					) : (
						<EmptyData />
					)}
				</div>
			)}
		</div>
	);
};
export default ProductTypesGraph;
