import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { getCountryName } from 'material-design/Statistic/StatisticCategory/StatisticByTerritoriesSection/StatisticByTerritoriesChart/utils/getCountryName';
import lscache from 'lscache';

import TopCountriesSkeleton from './Skeleton/TopCountriesSkeleton';
import EmptyData from '../EmptyData/EmptyData';
import styles from './TopCountries.module.css';

const TopCountries = ({
	geo,
	isLoadingTopCountries,
	topCountries,
	isDetailPage = true,
}) => {
	const lang = localStorage.getItem('lang');
	const countriesCache = lscache.get('countriesCashe');
	const [countries, setCountries] = useState([]);
	const [tenTopCountries, setTenTopCountries] = useState([]);

	const getCountriesList = () => {
		if (geo) {
			const result = geo.map((item) => ({
				code: item.id,
				name: item.properties.name,
			}));

			setCountries(result);
		}
	};

	useEffect(() => {
		if (geo.length) {
			getCountriesList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geo]);

	useEffect(() => {
		const getTenTopCountries = (data, countriesCache) => {
			let result = data.map((item) => {
				if (countriesCache) {
					const country = countriesCache?.filter(
						(el) => el.code === item.territory_code
					);
					if (country.length > 0) {
						item.title_ru = country[0].title_ru;
						item.title_en = country[0].title_en;
					}
					return item;
				}
				return item;
			});

			return result;
		};

		setTenTopCountries(getTenTopCountries(topCountries, countriesCache));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries, geo, topCountries]);

	const renderPercentChange = (percentChange) => {
		return (
			<p
				className={`${styles.progress} ${styles.upStreams} ${
					String(percentChange).includes('-') && styles.downStreams
				} ${percentChange === 0 && styles.normalPercent}`}
			>
				<span className={styles.progressValue}>
					<svg
						width="11"
						height="12"
						viewBox="0 0 11 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.16645 3.21875V11.3334H4.83312V3.21875L1.25712 6.79475L0.314453 5.85208L5.49979 0.666748L10.6851 5.85208L9.74245 6.79475L6.16645 3.21875Z"
							fill="currentColor"
						/>
					</svg>
					{percentChange}%
				</span>
			</p>
		);
	};

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.top-countries.title'} />
			</h2>
			{isLoadingTopCountries ? (
				<TopCountriesSkeleton />
			) : topCountries.length ? (
				<div className={styles.table}>
					<div
						className={`${styles.tableHeading} ${
							isDetailPage ? styles.tableHeadingDetail : {}
						}`}
					>
						<p className={styles.tableHeadingTitle}>
							<FormattedMessage
								id={'rod.statistic.trends.countries.title-country'}
							/>
						</p>
						<p className={styles.tableHeadingTitle}>
							<FormattedMessage id={'rod.statistic.trends.top.streams'} />
						</p>
						<p className={styles.tableHeadingTitle}>
							{isDetailPage ? (
								<FormattedMessage
									id={'rod.statistic.trends.countries.growth-week'}
								/>
							) : (
								<FormattedMessage
									id={'rod.statistic.trends.countries.percentage'}
								/>
							)}
						</p>

						{isDetailPage && (
							<p className={styles.tableHeadingTitle}>
								<FormattedMessage
									id={'rod.statistic.trends.countries.growth-month'}
								/>
							</p>
						)}
					</div>

					<ul className={styles.topTenData}>
						{tenTopCountries.map((item, index) => (
							<li
								className={`${styles.countryElement} ${
									isDetailPage ? styles.countryElementDetail : {}
								}`}
								key={getCountryName(item, lang)}
							>
								<div>
									<span className={styles.countryPlace}>{index + 1}</span>
									<span className={styles.countryName}>
										{getCountryName(item, lang)}
									</span>
								</div>
								<span className={styles.countryValue}>
									{Number(item.streams).toLocaleString('ru-RU')}
								</span>
								{!isDetailPage && (
									<span className={styles.countryPercentage}>
										{item.percentage}%
									</span>
								)}
								{isDetailPage && (
									<>
										<span className={styles.countryPercentage}>
											{item.week_growth_percentage === null ? (
												<>-</>
											) : (
												renderPercentChange(item.week_growth_percentage)
											)}
										</span>
										<span className={styles.countryPercentage}>
											{item.month_growth_percentage === null ? (
												<>-</>
											) : (
												renderPercentChange(item.month_growth_percentage)
											)}
										</span>
									</>
								)}
							</li>
						))}
					</ul>
				</div>
			) : (
				<div className={styles.empty}>
					<EmptyData />
				</div>
			)}
		</div>
	);
};

export default TopCountries;
