import React from 'react';

import styles from './ContentPage.module.css';
import Release from './Release/Release';
import TopRecordingsDetail from '../../TopRecordingsDetail/TopRecordingsDetail';

const ContentPage = ({ accountId, tab }) => {
	const renderScreen = (tab, accountId) => {
		switch (tab) {
			case 'release':
				return <Release accountId={accountId} />;
			case 'tracks':
				return <TopRecordingsDetail accountId={accountId} />;
			case 'audience':
				return <>аудитория</>;
			case 'sources':
				return <>источники</>;
			default:
				return <Release accountId={accountId} />;
		}
	};

	return <div className={styles.wrapper}>{renderScreen(tab, accountId)}</div>;
};

export default ContentPage;
